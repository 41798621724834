.cards__list {
  counter-reset: list;
}

.card {
  --card-shadow: none;
}

.card {
  height: 100%;

  &--shadow {
    --card-shadow: rgba(255, 255, 255, 0.5);

    background: var(--z-mercury);
    box-shadow: inset 0 0 calc(10 / var(--fz) * var(--fz-em)) 1px var(--card-shadow);
    padding: calc(40 / var(--fz) * var(--fz-em)) calc(20 / var(--fz) * var(--fz-em)) calc(30 / var(--fz) * var(--fz-em)) calc(10 / var(--fz) * var(--fz-em));
  }

  &--shadow-dark {
    --card-shadow: rgba(0, 0, 0, 0.1);

    box-shadow: inset 0 0 calc(5 / var(--fz) * var(--fz-em)) 1px var(--card-shadow);
    padding: calc(30 / var(--fz) * var(--fz-em));
    border-radius: 8px;
  }

  .cards__list--count & {
    padding: calc(40 / var(--fz) * var(--fz-em)) calc(20 / var(--fz) * var(--fz-em)) calc(30 / var(--fz) * var(--fz-em)) calc(40 / var(--fz) * var(--fz-em));
  }

  &::before {
    --text-shadow: rgba(0, 0, 0, 0.4);
    text-shadow: 1px 0 2px var(--text-shadow);

    .cards__list--count & {
      --fz: 30;

      top: calc(24 / var(--fz) * var(--fz-em));
      left: calc(20 / var(--fz) * var(--fz-em));
      font-weight: 700;
      position: absolute;
      counter-increment: list;
      content: counter(list, decimal-leading-zero);
      font-size: calc(var(--fz)/ var(--fz-base) * var(--fz-em));
      color: var(--z-white);
      opacity: 0.5;
      z-index: 0;
    }
  }

  &--primary {
    --card-shadow: hsl(15, 17%, 46%, 0.2);

    &::before {
      --text-shadow: var(--z-cinerious);
    }
  }

  &--secondary {
    --card-shadow: hsla(355, 98%, 46%, 0.2);

    &::before {
      --text-shadow: var(--text-color-highlighted-secondary);
    }
  }

  &--third {
    --card-shadow: var(--text-color-highlighted);

    &::before {
      --text-shadow: var(--text-color-highlighted);
    }
  }

  .cards__list--count & {
    position: relative;
  }

  &__body {
    position: relative;
  }

  &__title {
    --fz: 20;

    font-weight: 600;
    line-height: 1.2;
    font-size: calc(var(--fz)/ var(--fz-base) * var(--fz-em));
  }

  &__text {
    --fz: 16;

    font-size: calc(var(--fz)/ var(--fz-base) * var(--fz-em));
    margin-top: calc(20 / var(--fz) * var(--fz-em));

    p {
      line-height: 1.4;
    }
  }
}

a.tile {
  display: block;
}

.tile__image {
  position: relative;
  overflow: hidden;
  padding-bottom: 71%;
  height: 0;
}

.tile__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tile__img img {
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}